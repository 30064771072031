import React, { Component } from 'react';
import { withRouter } from 'react-router';

import UserPresent from '../components/UserPresent.js';

import Auth from '../utils/Auth.js';
import Users from '../utils/Users.js';

class Admin extends Component
{

	constructor( props ) 
	{
		super( props );
		
		this.state = {
		};
		
		if( !Auth.getToken()  ) {
		    this.props.history.push("/");
		}
	}
	
	
	componentDidMount() {
		this._asyncRequest = Users.getUsers().then(
			() => {
                if( Users.users ) {
                    const result = {};
                    for( let idx in Users.users ) {
                        const u = Users.users[ idx ];
                        console.log( u );
                        result[ u.id ] = u;
                    }
                    this.setState({
                        users: result,
                        uid: 0,
                        userpresents: null
                    });
                }   
                else {
                    console.error( 'failed' );
                }
			}
	  );
	}

    onSelectUser( evt )
    {
        if( this.state.uid !== evt.target.dataset['id'] ) {
            this.setState({
                uid: evt.target.dataset['id'] || 0,
                userpresents: null
            });
            this.requestUserPresents();
        }
    }
	
	requestUserPresents()
	{
		this._asyncRequest = Users.getPresents(this.state.uid).then(
			() => {
				this.setState({
					userpresents: Users.presents[ this.state.uid ] || [],
				});
			}
	    );
	}
	
	render()
	{
        console.log( 'render' );
		var userColumns = [];
		for( var idx in this.state.users ) {
			var u = this.state.users[ idx ];
            var selected = (this.state.uid || 0 ) === u.id;
            var cssClass = selected ? 'btn btn-primary' : 'btn btn-secondary';
			userColumns.push( <button key={u.id}  data-id={u.id} data-name={u.name} data-avatar={u.avatar} className={cssClass} onClick={this.onSelectUser.bind(this)}>{u.name}</button> );
		}

        const userPresents = [];
        if( this.state.userpresents )
        {
            for( var i in this.state.userpresents ) {
                var p = this.state.userpresents[ i ];
                userPresents.push( <UserPresent key={p.id}  
                                            id={p.id} 
                                            title={p.title} 
                                            description={p.description}
                                            link={p.link}
                                            canEdit={this.state.canEdit} /> );
            }
        }

		return (
				<div>
					<div className="row justify-content-center">
						<h3>Users</h3>
                        <div className="btn-group">
                            {userColumns}
                        </div>
                        { this.state.uid > 0 &&  
                            <div>
                                <h4>{this.state.users[ this.state.uid ].name}</h4>
                                { userPresents }
                            </div>
                            
                        }
					</div>
				</div>
				);
	}
}

export default withRouter( Admin );