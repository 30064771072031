import Auth from './Auth.js';
import $ from 'jquery';

var ajax =
{
	apiBase: 'https://kerst.duthler.net/api/',
	//apiBase: 'https://devel.duthler.net/kerst3/api/',
	apiUrl( path )
	{
		return ajax.apiBase + path;
	},
	get( url )
	{
		return $.ajax({
						type: "GET",
						url: url,
						dataType: 'json',
						async: true,
						headers: Auth.token
									? { 'x-auth-token': Auth.token }
									: { }
				})
				.done( function(data, textStatus, request) {
					var newToken = request.getResponseHeader('x-auth-token');
					if( newToken ) Auth.token = newToken;
				});
	},	
	post( url, data )
	{
		return $.ajax({
						type: "POST",
						url: url,
						dataType: 'json',
						async: true,
						data: JSON.stringify( data ),
						headers: Auth.token
									? { 'x-auth-token': Auth.token }
									: { }
				})
				.done( function(data, textStatus, request) {
					var newToken = request.getResponseHeader('x-auth-token');
					if( newToken ) {
						Auth.setToken( newToken );
					}
				});
	}
}

export default ajax;
