import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { UserPresentColumn } from '../components/UserPresentColumn';

import Auth from '../utils/Auth.js';
import Users from '../utils/Users.js';

class Kados extends Component
{
	static displayName = Kados.name;

	constructor( props ) 
	{
		super( props );
		
		this.state = {
			users: [],
		};
		
		if( !Auth.getToken()  ) {
		    this.props.history.push("/");
		}
	}
	
	
	componentDidMount() {
		this._asyncRequest = Users.getUsers().then(
			() => {
				this.setState({
					users: Users.users || [],
				});
			}
	  );
	}
	
	render()
	{
		const userColumns = [];
        console.error( this.props.mine );
        if( this.props.mine == '1' )
        {
            for( let i in this.state.users ) {
                let u = this.state.users[ i ];
                if(u.is_me === true ) {
                    console.log( 'me', u );
                    userColumns.push( <UserPresentColumn key={u.id}  id={u.id} name={u.name} avatar={u.avatar}  /> );
                }
            }
        }
        else {
            for( let i in this.state.users ) {
                let u = this.state.users[ i ];
                if( u.santa_for === true ) {
                    console.log(  'notme', u );
                    userColumns.push( <UserPresentColumn key={u.id}  id={u.id} name={u.name} avatar={u.avatar}  /> );
                }
            }
        }
		
		return (
				<div>
					<div className="row justify-content-center">
						{userColumns}
					</div>
				</div>
				);
	}
}

export default withRouter( Kados );