import React, { Component } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import Users from '../utils/Users.js';
import Auth from '../utils/Auth.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {

        super(props);

        this.state = {
            isLoggedIn: Auth.getToken()
        };


        var self = this;
        var onSetTokenPrev = Auth.onSetToken;
        Auth.onSetToken = function () {
            if (onSetTokenPrev) onSetTokenPrev();
            self.setState({ isLoggedIn: Auth.token ? true : false });
        };
    }


    onLogout() {
        Users.reset();
        Auth.setToken(null);
    }

    render() {

        var mineActive = (window.location.pathname === '/kados') ? 'active' : '';
        var otherActive = (window.location.pathname === '/kados/other') ? 'active' : '';
        var noActive = (window.location.pathname === '/no') ? 'active' : '';

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm bg-info bg-gradient border-bottom box-shadow mb-3" >
                    <div >
                        <div className="row">
                            <div className="col-12">
                                {this.state.isLoggedIn &&
                                    <button className="float-end btn btn-light btn-sm" onClick={this.onLogout.bind(this)}>
                                        <FontAwesomeIcon icon="sign-out-alt" /> Uitloggen
                                    </button>
                                }
                                <NavbarBrand tag={Link} to="/" className="text-light">Kerst Wensenlijst</NavbarBrand>
                            </div>
                        </div>
                        {this.state.isLoggedIn &&
                            <div className="row">
                                <a id="btn-show-mine" className={'col-5 btn btn-outline-primary btn-light rounded-0 ' + mineActive} href="/kados">
                                    Mijn Wensenlijst
                                </a>
                                <a id="btn-show-other" className={'col-5 btn btn-outline-primary btn-light rounded-0 ' + otherActive} href="/kados/other">
                                    Wie heb ik getrokken?
                                </a>
                                <a id="btn-show-no" className={'col-2 btn btn-outline-primary btn-light rounded-0 ' + noActive} href="/no">
                                    <small><small>Wie koopt<br />mijn kados?</small></small>
                                </a>
                            </div>
                        }
                    </div>
                </Navbar>
            </header>
        );
    }
}
