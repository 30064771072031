import React, { Component } from 'react';
import { withRouter } from 'react-router';

class No extends Component
{
    strings = [
        'Wat dacht je? Nu weten we wel allemaal dat je wilde spieken!',
        'Ha! Dacht het dus niet!',
        'Gaat niet gebeuren, stiekemerd!',
        'Wahahahaahahahaaha!',
        'Nee dus!',
    ]

	constructor( props ) 
	{
		super( props );
		
		this.state = {
		};
	}
	
	
	componentDidMount() {
	}

	
	render()
	{
        const str = this.strings[ Math.floor( Math.random() * this.strings.length ) ];
		return ( <div>
					<div className="row justify-content-center text-center">
						<h3>{str}</h3>
                    </div>
				</div> );
	}
}

export default withRouter( No );